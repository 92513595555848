<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">{{ getCommonText('decoding') }}. {{ currCategory[categoryFieldNames[lng]] }}</p>
            </div>
            <div class="right-content">
                <template v-if="categoryMode">
                    <c-budget-forms-data-transfer
                        v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                        :header="header"
                        :lng="lng"
                        :getDataTransferText="getDataTransferText"
                        :getErrText="getErrText"
                        :makeToast="makeToast"
                        @reloadTable="loadDatas"
                    />
                    <div class="filter-actions filter-actions-flex">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button variant="primary" @click="onAddNewCat" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    </div>
                </template>
                <template v-else>
                    <b-button variant="light" @click="resetModal">
                        <i class="icon icon-keyboard icon-rotate-180"></i>{{ getCommonText('back_to_list') }}
                    </b-button>
                    <b-button :style="{ 'minWidth': '110px' }" v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                        <i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}
                    </b-button>
                    <b-button
                        :style="{ 'minWidth': '110px' }" 
                        :disabled="!dataChanged || goodsAreSaving"
                        variant="success"
                        @click="prepareForSave">
                        {{ getCommonText('save') }}
                    </b-button>
                </template>
            </div>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering"></td>
                    <td class="td-numbering text-center table-success">1</td>
                    <td class="td-numbering text-center table-danger">2</td>
                    <td class="td-numbering text-center table-info">3</td>
                    <td class="td-numbering text-center table-primary">4</td>
                    <td class="td-numbering text-center table-warning">5</td>
                    <td class="td-numbering text-center table-success">6</td>
                    <td class="td-numbering text-center table-danger">7</td>
                    <td class="td-numbering text-center table-info">8</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')" 
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getCommonText('all_cats') : getCommonText('selected_cats')}`)"/>
                    </div>
                </template>
                <template #cell(selection)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(action)="data">
                    <button
                        class="table-plus"
                        v-b-modal.goods-modal @click="setCurrCatName(data.item)"
                        :disabled="!(data.item.recipient || data.item.recipient_kk) || data.item.nameUpdating">
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(recipient)="data">
                    <div
                        class="column-width-100 bp-category-name">
                        <div>{{ data.item[categoryFieldNames[lng]] }}</div>
                        <i 
                            v-if="variantAttribute"
                            class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                            @click="onEditCatName(data.item)"
                        ></i>
                    </div>
                </template>
                <template #cell(average_meals)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :disabled="data.item.newCat || isEditForbidden(data.item)"
                                  v-model="data.item.average_meals"
                                  @update="data.item.rowToSave = true"
                                  @keyup="checkNumber($event, 1)"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @change="inputFixed(data.item, 'average_meals', data.item.average_meals, 0)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(func_day)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :disabled="data.item.newCat || isEditForbidden(data.item)"
                                  v-model="data.item.func_day"
                                  @update="data.item.rowToSave = true"
                                  @keyup.enter.exact="keyup13"
                                  @keyup="checkNumber($event, 1, 365)"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @change="inputFixed(data.item, 'func_day', data.item.func_day, 0)">
                    </b-form-input>
                    <div v-else class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(cost_meals)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(note)="data">
                    <b-form-textarea v-if="variantAttribute"
                        style="min-height: 100px"
                        :disabled="data.item.newCat || isEditForbidden(data.item)"
                        v-model.trim="data.item.note"
                        :formatter="(e) => formatText(e, 100)"
                        @change="saveCats(data.item)"
                        @update="data.item.rowToSave = true"
                        @keyup.enter.exact="keyup13"
                    ></b-form-textarea>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick({ ...data.item, name_ru: data.item[categoryFieldNames[lng]]})"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" v-if="variantAttribute" :disabled="deletingCat" @click="deleteItem(`${getCommonText('curr_cat')}`, {...data.item, good_type: data.item}, data.index, true)"></i>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="7">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
            <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
                <span @click="onAddNewCat"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
            </div>
        </div>
        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <form-03149-template1
                    ref="formTemplate"
                    :formCode="form.code"
                    :goodsData="goodsData"
                    :initialGoodsData="initialGoodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @onDataChanged="onDataChanged"
                    @searchName="searchName"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    @doublesValidationDecodes="doublesValidationDecodes"
                    :triggDownloadFile="triggDownloadFile"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                    :isDoubleDecodeExist="isDoubleDecodeExist"
                ></form-03149-template1>
                <div class="table-add" v-if="variantAttribute">
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> Добавить</span>
                </div>
            </b-overlay>
        </template>
        
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
            @onFilePreview="onFilePreview"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
        <c-set-b-f-category-name
            ref="setCatNameModal"
            :budgetForm="budgetForm"
            :categoryFieldNames="categoryFieldNames"
            :lng="lng"
            :getCommonText="getCommonText"
            @addItem="addItem"
            @onCatNameChanged="onCatNameChanged"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import Form03149Template1 from "./components/budget-forms-template1.vue";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CSetBFCategoryName from "./components/c-set-bf-category-name.vue";
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form03_141',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        Form03149Template1, 
        ModalAllFilesManagement, 
        CBudgetFormsCopyData,
        CSetBFCategoryName,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '03-141',
                name_kk: 'Ветеринариялық мекемелерiнде тамақтануға арналған шығыстарды есептеу',
                name_ru: 'Расчет расходов на питание в ветеринарных учреждениях'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false, 
            savingAtemptsLimit: 3,
            categoryFieldNames: {ru: "recipient", kk: "recipient_kk"}
        };
    },
    mounted() {
        this.progress = 15;
    },
    methods: {
        async delete(item, showMsg = true) {
            this.$set(item, 'form', this.form.code);
            this.$set(item, 'mode', this.header.mode);
            try {
                const response = await fetch('/api-py/delete-budget-request-form/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (showMsg) {
                        await this.updateDatas(true);
                        this.makeToast('success', this.getErrText('msg'), this.getErrText('one_deleted'));
                    }
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            }
        }, // удаление данных

        inputFixed(item, field, value, digit) {
            if (!value) value = 1;
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
            const costMeals = this.calcCostMeals(item);
            this.$set(item, 'cost_meals', costMeals);
            if (!item.recipient && !item.recipient_kk) return;
            this.resetSavingAtemptsLimit();
            this.saveCats(item);
        }, // форматирует введенное значение до digit цифр после запятой

        calcCostMeals(item) {
            const cost = parseFloat(item.total) / parseInt(item.func_day) / parseInt(item.average_meals);
            return parseFloat(cost.toFixed(2))
        },

        itemUpdate(item, nameRu, nameKk) {
            this.$set(item, 'recipient', nameRu);
            this.$set(item, 'recipient_kk', nameKk);
            this.$set(item, 'average_meals', 1);
            this.$set(item, 'func_day', this.getNumberOfDaysInYear(this.header.year));
            this.$set(item, 'cost_meals', 0);
            this.$set(item, 'note', null);
            this.$set(item, 'total', 0);
            this.$set(item, 'total_thnds', 0);
            this.$set(item, 'newCat', true);
            this.$set(item, 'nameUpdating', false);
            const uuidcode = this.uuidv4();
            this.$set(item, 'category_id', uuidcode);
            this.$set(item, 'code', uuidcode);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'oldName', nameRu);
            this.$set(item, 'oldName_kk', nameKk);
            this.$set(item, 'rowToSave', true);
        },
        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadCategoryData() {
            this.load = true;
            await this.loadDatas();
            if (!this.budgetForm.length) return;
            const catIdList = [];
            this.budgetForm.forEach(item => catIdList.push(item.category_id))
            this.$set(this.header, 'cat_id_list', catIdList)
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        this.budgetForm.forEach(item => {
                            const currVal = values.find(val => val.category_id === item.category_id);
                            if (currVal) {
                                this.$set(item, 'total', currVal.sum_total);
                                this.$set(item, 'total_thnds', parseFloat((currVal.sum_total/1000).toFixed(3)));
                                this.$set(item, 'files', this.getUnicFilesArr(currVal));
                                const costMeals = this.calcCostMeals(item);
                                if (this.variantAttribute && item.cost_meals !== costMeals) {
                                    this.$set(item, 'cost_meals', costMeals);
                                    this.$set(item, 'rowToSave', true);
                                    if (this.variantAttribute && this.savingAtemptsLimit > 0) {
                                        this.saveCats(item, false);
                                        --this.savingAtemptsLimit;
                                    }
                                }
                            } else {
                                this.$set(item, 'total', 0);
                                this.$set(item, 'files', []);
                                if (this.variantAttribute && item.cost_meals !== 0) {
                                    this.$set(item, 'cost_meals', 0);
                                    this.$set(item, 'rowToSave', true);
                                    if (this.variantAttribute && this.savingAtemptsLimit > 0) {
                                        this.saveCats(item, false);
                                        --this.savingAtemptsLimit;
                                    }
                                }
                            }
                        }); //привеодим данные в таблице категорий в соответствие с расшифровками
                    }
            } catch (error) {
                this.makeToast('danger', `${this.this.getErrText('bad_request')} loadCategoryData()`, error.toString());
            }
            this.load = false;
        },

        async loadDatas() {
            this.load = true;
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form/' + JSON.stringify(this.header));
                values = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.this.getErrText('bad_request')} loadDatas()`, error.toString());
            }

            const newBudgetDatas = [];
            await values.forEach(val => {
                const item = {
                    id: val.id,
                    recipient: val.recipient,
                    recipient_kk: val.recipient_kk,
                    average_meals: val.average_meals,
                    func_day: val.func_day,
                    cost_meals: val.cost_meals,
                    category_id: val.category_id,
                    code: val.category_id,
                    total: 0,
                    total_thnds: 0,
                    rowToSave: false,
                    newCat: false,
                    oldName: val.recipient,
                    oldName_kk: val.recipient_kk,
                    nameUpdating: false,
                    itemToDelete: false,
                    note: val.note
                };
                newBudgetDatas.push(item);
            });
            this.budgetForm = newBudgetDatas;
            this.budgetForm.sort((a, b) => a.id - b.id);
            this.load = false;
        },

        fillDataForSave(item, row) {
            this.$set(item, 'category_id', this.currCategory.category_id);
            this.$set(item, 'amount', parseFloat(row.amount));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        getFormCode() {
            // при необходимости заменить в родительском компоненте. Напр. Form01_142.vue
            return `${this.form.code}-decode`;
        },

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        getCodeFieldName() {
            return 'code';
        }, // при необходимости заменить в родителе

        getTotalFieldName() {
            return 'total_thnds'
        }, // имя поля с тоталом, при необходимости заменить в родителе

         // пересчет тотала при удалении нескольких записей
        calcTotalBeforeDel() {
            return this.budgetForm.length ? this.budgetForm.reduce((acc, sum) => acc + sum.total_thnds, 0) : 0;
        },

        isCatShouldBeSaved(row, bf) {
            return bf.id !== row.id && (bf.recipient !== bf.oldName || bf.recipient_kk !== bf.oldName_kk);
        },

        onCatNameChanged(row) {
            this.$set(row, 'rowToSave', true);
            this.prepareForSaveCats(this.budgetForm);
        }
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total_thnds);
            }
            return Math.ceil(sum);
        },
        averageMeals() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.average_meals);
            }
            return sum;
        },
        getFormText() {
            return this.setFormText('form_n_141.');
        },
        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'selection',
                        label: ' '
                    },
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'index',
                        label: ' № '
                    },
                    {
                        key: 'recipient',
                        label: this.getFormText('receiver_name')
                    },
                    {
                        key: 'average_meals',
                        label: this.getFormText('avg_consumer_count')
                    },
                    {
                        key: 'func_day',
                        label: this.getFormText('function_days')
                    },
                    {
                        key: 'cost_meals',
                        label: this.getFormText('day_food_norm')
                    },
                    {
                        key: 'total_thnds',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'note',
                        label: this.getCommonText('notes')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
};
</script>

<style scoped>
    .error input {
        padding-left: 25px;
    }
    .filter-actions-flex {
        display: flex;
    }
    .table-plus {
        border: none;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }
    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }
</style>